<!-- 家校训练服务-上课 -->

<template>
  <div>
    <a-card>
      <a-row style="margin-bottom: 32px">
        <a-col :span="12" :offset="6">
          <a-steps :current="currentType">
            <a-step title="选择训练目标" />
            <a-step title="设置行为记录" />
          </a-steps>
        </a-col>
        <a-col :span="6" style="display: flex; justify-content: flex-end">
          <a-button icon="left" @click="handleReturn">返回</a-button>
        </a-col>
      </a-row>
      <training-goal v-if="currentType == 0" @changeStep="changeStep"></training-goal>
      <behavior-record v-if="currentType == 1"></behavior-record>
    </a-card>
  </div>
</template>
<script>
import trainingGoal from './training-goal.vue'
import behaviorRecord from './behavior-record.vue'
export default {
  components: {
    trainingGoal,
    behaviorRecord
  },
  data() {
    return {
      currentType: 0
    }
  },
  methods: {
    handleReturn() {
      if (this.currentType === 0) {
        this.$router.push({
          name: 'homeSchoolTrainingServiceMenu'
        })
      }
      if (this.currentType === 1) {
        this.currentType = 0
      }
    },
    changeStep(val) {
      this.currentType = val
    }
  }
}
</script>
<style lang="less"></style>
