<!-- 家校训练服务-上课-设置行为记录 -->
<template>
  <div class="classroom-behavior-record">
    <div class="content">
      <a-spin :spinning="spinning.spin1">
        <div class="title">
          <div class="left">
            本次课是否需要行为记录：
            <a-radio-group v-model="isNeedBehavior">
              <a-radio :value="true"> 需要 </a-radio>
              <a-radio :value="false"> 不需要 </a-radio>
            </a-radio-group>
          </div>
        </div>
        <div class="material-box material-box-add" :class="{ 'material-box-disabled': !isNeedBehavior }">
          <div class="title">
            <div class="left">本次课行为记录</div>
          </div>
          <div class="material">
            <a-badge v-for="(item, index) in materialAdded" :key="index" style="position: relative">
              <li @click="indexBadge = index" :class="index == indexBadge ? 'li-active' : ''">
                {{ item.name }}
              </li>
              <a-icon
                v-if="index == indexBadge"
                slot="count"
                type="line"
                @click="handleDeleteBadge({ item, index })"
                style="
                  position: absolute;
                  padding: 2px;
                  right: 24px;
                  border-radius: 50%;
                  background-color: #ff6971;
                  color: #fff;
                "
              />
            </a-badge>
            <div class="count">{{ materialAdded.length }}/{{ maxNumber }}</div>
          </div>
        </div>
        <div class="material-box" :class="{ 'material-box-disabled': !isNeedBehavior }">
          <div class="title">
            <div class="left">行为记录库</div>
            <div class="right">
              <a-button type="link" @click="show = true" :disabled="!isNeedBehavior">新增行为记录</a-button>
            </div>
          </div>
          <div class="material">
            <li v-for="(item, index) in material" :key="index" :class="item.isSelected ? 'li-active' : ''">
              <div @click="addMaterial(item, index)">{{ item.name }}</div>
              <a-popover trigger="click" placement="bottomRight" v-if="item.type != 'SYSTEM'">
                <template slot="content">
                  <p class="popover-text" @click="deleteMaterialSelf({ item, index })">删除</p>
                </template>
                <a-button style="position: absolute; top: -1px; right: 0px" type="link" icon="more" class="option">
                </a-button>
              </a-popover>
            </li>
          </div>
        </div>
        <a-row type="flex" align="middle" justify="center">
          <a-button class="add" :loading="spinning.spin1" :disabled="spinning.spin1" @click="classBegin()"
            >开始上课</a-button
          >
        </a-row>
      </a-spin>
    </div>
    <!--弹窗-->
    <a-modal
      v-model="show"
      title="添加行为记录"
      width="400px"
      :maskClosable="false"
      :destroyOnClose="true"
      :bodyStyle="{ padding: 0 }"
      :ok-button-props="{ props: { shape: 'round', loading: spinning.spin2 } }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="handleOk"
      @cancel="form = { name: '', recordingMethodList: [] }"
    >
      <a-row style="padding: 24px">
        <a-form-model ref="form" :form="form" class="ant-advanced-search-form">
          <a-form-model-item label="行为记录名称：" prop="name">
            <a-input v-model="form.name" allowClear placeholder="请输入行为记录名称" :maxLength="6" />
          </a-form-model-item>
          <a-form-model-item label="行为记录类别：" prop="type">
            <a-select v-model="form.recordingMethodList" placeholder="请选择行为记录类别" mode="multiple">
              <a-select-option value="TIMING">计时</a-select-option>
              <a-select-option value="COUNT">计次</a-select-option>
              <a-select-option value="TIMING_COUNT">计时计次</a-select-option>
              <a-select-option value="COUNTDOWN_COUNT">固定时长计次</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import * as Api from '@/api/serviceManager'
export default {
  data() {
    return {
      isNeedBehavior: true, // Whether behavior record is required. True is required. False is not required
      query: {}, // url参数
      tempDelete: {}, // 删除素材临时数据
      form: {
        name: '',
        recordingMethodList: []
      },
      material: [], // 所有素材
      materialAdded: [], // 添加的素材
      show: false, // 添加弹窗
      actions: [
        {
          text: '删除'
        }
      ],
      indexBadge: Number, // 红色删除图标
      maxNumber: 6, // 最多6个
      spinning: {
        spin1: false,
        spin2: false
      }
    }
  },
  created() {
    this.query = this.$route.query
    this.skillPageDetail()
  },
  methods: {
    // 详情
    skillPageDetail() {
      const { studentId: id } = this.query
      const params = {
        id
      }
      this.spinning.spin1 = true
      Api.getBehaviorRecords(params)
        .then(res => {
          const { code, data } = res
          this.spinning.spin1 = false
          if (code === 0) {
            this.material = data
            this.materialAdded = data.filter(item => {
              return item.isSelected === true
            })
          }
        })
        .catch(() => {
          this.spinning.spin1 = false
        })
    },
    // 确认
    handleOk() {
      const { name, recordingMethodList } = this.form
      if (!name) {
        this.$notification['error']({
          message: '提示',
          description: '请输入行为记录名称'
        })
        return
      }
      if (!recordingMethodList.length) {
        this.$notification['error']({
          message: '提示',
          description: '请选择行为记录类别'
        })
        return
      }
      const params = {
        name,
        recordingMethodList,
        studentId: this.query.studentId
      }
      this.spinning.spin2 = true
      Api.addBehaviorRecords(params)
        .then(res => {
          this.spinning.spin2 = false
          if (res.code === 0) {
            this.show = false
            // 重新拉详情
            this.skillPageDetail()
            // 重置
            this.form = {
              name: '',
              recordingMethodList: []
            }
          }
        })
        .catch(() => {
          this.spinning.spin2 = false
        })
    },
    // 添加素材至当前训练素材
    addMaterial(item, index) {
      const { isSelected } = item
      if (isSelected) {
        this.$notification['error']({
          message: '提示',
          description: `请勿重复添加`
        })
        return
      }

      if (this.materialAdded.length >= this.maxNumber) {
        this.$notification['error']({
          message: '提示',
          description: `最多只能添加${this.maxNumber}个`
        })
        return
      }
      const _index = this.materialAdded.findIndex(item1 => item1.id === item.id)
      if (_index === -1) {
        item.isSelected = true
        this.materialAdded.push(item)
      }
      this.markMaterial()
    },

    // 删除素材
    handleDeleteBadge({ index }) {
      if (this.materialAdded.length < 2) {
        this.$notification['error']({
          message: '提示',
          description: '【本次课行为记录】至少有1个'
        })
        return
      }
      // 从所有删除
      this.materialAdded.splice(index, 1)
      this.indexBadge = Number
      this.markMaterial()
    },

    // 删除自己添加
    deleteMaterialSelf({ item, index }) {
      const _index = this.materialAdded.findIndex(el => el.id === item.id)
      if (_index > -1) {
        this.$notification['error']({
          message: '提示',
          description: '请先将素材从【本次课行为记录】中移除'
        })
        return
      }
      const param = {
        behaviorRecordId: item.id,
        studentId: this.query.studentId
      }

      const confirm = this.$confirm({
        title: '确定删除',
        content: `行为记录：${item.name}`,
        onOk: () => {
          // 从所有删除
          this.material.splice(index, 1)
          this.spinning.spin1 = true
          Api.deleteBehaviorRecords(param)
            .then(res => {
              this.spinning.spin1 = false
              if (res.code === 0) {
                this.skillPageDetail()
              }
            })
            .catch(() => {
              confirm.destroy()
              this.spinning.spin1 = false
            })
        }
      })
    },

    // 标记已添加
    markMaterial() {
      const behaviorRecordIdList = []
      this.materialAdded.forEach(item => {
        behaviorRecordIdList.push(item.id)
      })
      const params = {
        behaviorRecordIdList,
        studentId: this.query.studentId
      }
      this.spinning.spin1 = true
      Api.markBehaviorRecords(params)
        .then(res => {
          this.spinning.spin1 = false
          if (res.code === 0) {
            this.indexBadge = Number
            this.skillPageDetail()
          }
        })
        .catch(() => {
          this.spinning.spin1 = false
        })
    },
    // 开始上课
    classBegin() {
      // if (!this.materialAdded.length) {
      //   this.$notification['error']({
      //     message: '【本次课行为记录】不能为空'
      //   })
      //   return
      // }
      const {
        query: { studentId, teacher, username, sex }
      } = this
      this.spinning.spin1 = true
      const params = {
        studentId,
        isBehaviorRecord: this.isNeedBehavior
      }
      Api.getTeachingId(params)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            const query = {
              studentId,
              teachingClassId: data,
              teacher,
              username,
              sex
            }
            this.$router.push({
              name: 'homeSchoolClassesBegin',
              query
            })
          }
        })
        .catch(() => {
          this.spinning.spin1 = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}

.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}

.classroom-behavior-record {
  .content {
    .material-box {
      margin-bottom: 35px;
      position: relative;

      .description {
        margin: 4px 0 12px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }

      .material {
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        padding: 24px;
        min-height: 100px;
        border: 1px solid #dfe0eb;
        align-content: flex-start;

        li {
          position: relative;
          width: fit-content;
          height: 32px;
          padding: 0 24px;
          text-align: center;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.8);
          font-size: 12px;
          font-weight: 400;
          margin: 0 24px 24px 0;
          border: 1px solid #dfe0eb;
          border-radius: 5px;

          .option {
            width: 20px;
          }
        }

        .li-active {
          color: #4760c6;
          background: #e1e8ff;
          border: 1px solid #e1e8ff;
        }
      }
    }

    .material-box-disabled {
      &::before {
        position: absolute;
        display: inline-block;
        cursor: not-allowed;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        /*禁用的时候,需求方想要颜色的时候再放开*/
        /*background-color: rgba(245, 245, 245, 0.25);*/
      }

      .material {
        li {
          color: #b7b7b7 !important;
          background: #f5f5f5 !important;
          border: 1px solid #dbdbdb !important;
        }
      }
    }

    .material-box:last-child {
      margin-bottom: 0;
    }

    .material-box-add {
      .material {
        li {
          border: 1px solid #4760c6;
          color: #4760c6;
        }

        .count {
          position: absolute;
          right: 10px;
          bottom: 10px;
          color: #8c8c8c;
        }
      }
    }
  }
}
.add {
  margin-top: 30px;
  width: 160px;
  height: 40px;
  line-height: 40px;
  background: #3a5ff8;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.title {
  display: flex;
  justify-content: space-between;

  .left {
    font-size: 16px;
    font-weight: 600;
    color: #595959;
    line-height: 32px;
  }
}
.popover-text {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 0;
}
</style>
