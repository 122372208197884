<!-- 家校训练服务-上课-选择训练目标 -->
<template>
  <div class="training-goal">
    <a-spin :spinning="spinning">
      <a-empty v-if="empty" style="margin-bottom: 20px" />
      <a-checkbox-group v-model="planSkillId" v-else>
        <div v-for="(item, index) in list" :key="index" style="margin-bottom: 16px">
          <a-checkbox :value="item.id" class="option-box">
            <div class="option">
              <div class="option_l">
                <div>{{ item.skillVO.name }}</div>
                <div>
                  第{{ convertToChineseNumber(item.practiceSkillPhase.phaseNumber) }}阶：{{
                    item.practiceSkillPhase.name
                  }}
                </div>
              </div>
              <div class="option_r">{{ item.practiceSkillPhase.minimumRounds }}次</div>
            </div>
          </a-checkbox>
        </div>
      </a-checkbox-group>
      <a-row type="flex" align="middle" justify="center" style="padding-top: 20px">
        <div class="nextBtn" @click="handleNextStep">下一步</div>
      </a-row>
    </a-spin>
    <!--弹窗-->
    <a-modal title="提示" :width="400" :visible="isPrompt" :closable="false">
      <a-row type="flex"> 计划中的长期目标已发生变化，请重新选择</a-row>
      <template slot="footer">
        <a-button type="primary" @click="handleOk">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as serviceManagerApi from '@/api/serviceManager'
import * as studentApi from '@/api/student'
import { convertToChineseNumber } from '@/utils/util'
export default {
  data() {
    return {
      planSkillId: [], // 选中
      list: [], // 列表
      query: {}, // url 参数
      spinning: false, // loading
      empty: false,
      isPrompt: false
    }
  },
  created() {
    this.query = this.$route.query
    this.initData()
  },
  methods: {
    requestType() {
      const { isUnfinished = false, studentId } = this.query
      const params = { studentId }
      return isUnfinished ? studentApi.beginNewClass(params) : serviceManagerApi.getSkillList(params)
    },
    // 初始化数据
    initData() {
      this.spinning = true
      this.requestType().then(res => {
        this.spinning = false
        const {
          code,
          data: { isPrompt, practiceSkillList }
        } = res
        if (code === 0) {
          if (practiceSkillList.length === 0) this.empty = true
          this.isPrompt = isPrompt
          this.list = practiceSkillList
          // 设置默认选中
          for (const item of practiceSkillList) {
            const { isSelected, id } = item
            if (isSelected) {
              this.planSkillId.push(id)
            }
          }
        }
      })
    },
    handleOk() {
      this.isPrompt = false
    },
    handleNextStep() {
      const { studentId } = this.query
      if (this.planSkillId.length === 0) {
        this.$notification['error']({ message: '请选择技能' })
        return
      }
      const params = { studentId, planSkillId: this.planSkillId }
      this.spinning = true
      serviceManagerApi.saveSkillList(params).then(res => {
        this.spinning = false
        const { code } = res
        if (code === 0) {
          this.$emit('changeStep', 1)
        }
      })
    },
    convertToChineseNumber
  }
}
</script>
<style lang="less">
.training-goal {
  .ant-checkbox-group {
    width: 100%;
    height: calc(100vh - 291px);
    overflow-y: auto;
    .option-box {
      width: 100%;
      display: flex;
      align-items: center;
      > :nth-of-type(2) {
        display: inline-block;
        width: 100%;
        background: #f7f8fc;
        padding: 0;
        margin-left: 8px;
        .option {
          padding: 6px 12px;
          display: flex;
          width: 100%;
          height: 70px;
          > div {
            align-self: center;
          }
          .option_l {
            width: 100%;
            :nth-of-type(1) {
              font-size: 18px;
              font-weight: 500;
              color: #333333;
            }
            :nth-of-type(2) {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
            :nth-of-type(3) {
              font-size: 14px;
              font-weight: 400;
              color: #8c8c8c;
            }
          }
          .option_r {
            width: 50px;
            text-align: right;
            font-size: 14px;
            font-weight: 400;
            color: #8c8c8c;
          }
        }
      }
    }
  }
  .nextBtn {
    width: 160px;
    height: 40px;
    line-height: 40px;
    background: #3a5ff8;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }
}

/*定义滚动条高宽及背景
高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.08);
}
/*定义滚动条轨道
 内阴影+圆角*/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0);
}
/*定义滑块
 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.08);
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
